const NavUser = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.9987 8.75033C11.9547 8.75033 13.5404 7.16467 13.5404 5.20866C13.5404 3.25265 11.9547 1.66699 9.9987 1.66699C8.04269 1.66699 6.45703 3.25265 6.45703 5.20866C6.45703 7.16467 8.04269 8.75033 9.9987 8.75033Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16797 16.666C2.16797 13.4904 4.74233 10.916 7.91797 10.916H12.0846C15.2603 10.916 17.8346 13.4904 17.8346 16.666V18.3327C17.8346 18.7469 17.4988 19.0827 17.0846 19.0827H4.58464C3.24995 19.0827 2.16797 18.0007 2.16797 16.666ZM7.91797 12.416C5.57076 12.416 3.66797 14.3188 3.66797 16.666C3.66797 17.1723 4.07838 17.5827 4.58464 17.5827H16.3346V16.666C16.3346 14.3188 14.4318 12.416 12.0846 12.416H7.91797Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default NavUser
